<template>
  <div class="menu-manage clearfix">
    <!-- <div class="menu-tree pull-left box-shadow"  v-loading="treeLoading"> -->
    <div class="box-shadow"  v-loading="treeLoading">

      <div class="menu-title clearfix">
        <span>栏目</span>
        <button class="btn-search pull-right" @click="append()">增加一级栏目</button>
      </div>
      <el-table
        :data="menuList"
        :header-cell-style="{background: '#eee', color: '#333'}"
        row-key="id"
        default-expand-all
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        >
        <el-table-column
          prop="name"
          label="栏目名称"
          class-name="pad-left-20"
          min-width="30%">
        </el-table-column>
        <el-table-column
          prop="code"
          label="CODE"
          align="center"
          min-width="15%">
        </el-table-column>
        <el-table-column
          prop="sort"
          label="排序"
          align="center"
          min-width="15%">
        </el-table-column>
        <el-table-column
          prop="isHide"
          label="是否显示"
          align="center"
          min-width="15%">
          <template slot-scope="scope">
            <span v-if="scope.row.isHide == 1">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="isTarget"
          label="打开方式"
          align="center"
          min-width="20%">
          <template slot-scope="scope">
            <span v-if="scope.row.isTarget == 1">新窗口</span>
            <span v-else>本窗口</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="id"
          label="操作"
          min-width="40%">
          <template slot-scope="scope">
          <el-button size="mini" type="primary" @click.prevent="clickItem(scope.row)">编辑</el-button>
            <el-button v-if="scope.row.type == 1" size="mini" type="warning" @click.prevent="append(scope.row)">新建</el-button>
            <el-button v-if="scope.row.type == 1" size="mini" type="success" @click.prevent="Article(scope.row.id)">文章管理</el-button>
            <el-button size="mini" type="danger" @click.prevent="remove(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-tree
      :data="menuList"
      node-key="id"
      default-expand-all
      :expand-on-click-node="false">
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span  @click="clickItem(data)">{{ data.name }}</span>
        <span class="operation">
          <el-button
            style="margin-left:15px"
            type="text"
            size="mini"
            @click="() => append(node, data)">
            新建
          </el-button>
          <el-button
            style="margin-left:15px"
            v-if="!data.children"
            type="text"
            size="mini"
            @click="() => remove(node, data)">
            删除
          </el-button>
        </span>
      </span>
    </el-tree> -->
    </div>
    <el-dialog :visible.sync="dialogVisible" width="80%" @close="guanbi" v-loading="menuItemLading" style="z-index: 10;">
      <div class="menu-title">目录详情</div>
      <el-form ref="menuItem" :model="menuItem"  label-width="100px" :rules="rules">
        <el-form-item label="栏目名称"  prop="name">
          <el-input v-model="menuItem.name"></el-input>
        </el-form-item>
        <el-form-item label="栏目编码" prop="code">
          <el-input v-model="menuItem.code"></el-input>
        </el-form-item>
        <el-form-item label="栏目类型">
          <el-radio v-model="menuItem.type" :label="1">普通栏目</el-radio>
          <el-radio v-model="menuItem.type" :label="2">独立栏目</el-radio>
        </el-form-item>
        <el-form-item label="链接地址">
          <el-input v-model="menuItem.url"></el-input>
        </el-form-item>
        <!-- <el-form-item label="图标字体">
          <el-input v-model="menuItem.icon"></el-input>
        </el-form-item> -->
        <el-form-item label="排序">
          <el-input-number size="medium" v-model="menuItem.sort"></el-input-number>
        </el-form-item>
        <el-form-item label="是否显示">
          <el-radio v-model="menuItem.isHide" label="1">是</el-radio>
          <el-radio v-model="menuItem.isHide" label="2">否</el-radio>
        </el-form-item>
        <el-form-item label="打开方式">
          <el-radio v-model="menuItem.isTarget" label="1">新窗口</el-radio>
          <el-radio v-model="menuItem.isTarget" label="2">本窗口</el-radio>
        </el-form-item>
        <!-- <el-form-item label="备注">
          <el-input v-model="menuItem.remarks"></el-input>
        </el-form-item> -->
        <el-form-item v-if="menuItem.type == 2" label="文章">
          <!-- <div style="position: relative;line-height: 20px;"> -->
          <div style="position: relative;line-height: 20px;">
            <vue-ueditor-wrap v-model="data.content" :config="myConfig"></vue-ueditor-wrap>
          </div>
          <!-- <Editoritem v-model="data.content" :isClear="isClear" @TextChange="TextChange" @change="change"></Editoritem> -->
        </el-form-item>
      </el-form>
      <div style="text-align:center">
        <button class="btn-search" @click="submit()">提交</button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import util from '@/utils/utils'
import VueUeditorWrap from 'vue-ueditor-wrap'
import { GetColumnList, AddColumnList, UndateColumnList, GetColumnListById, ColumnDelete } from '@/api/http'
export default {
  name: 'SettingMenu',
  data () {
    return {
      myConfig: {
        UEDITOR_HOME_URL: '/ueditor/',
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 400,
        zIndex: 20,
        enableAutoSave: false,
        // 初始容器宽度
        initialFrameWidth: '100%',
        // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
        // serverUrl: 'http://35.201.165.105:8000/controller.php'
        serverUrl: '/api/system/w/uploadEdImg',
        imageAllowFilles: ['.jpeg', '.jpg', '.png'],
        imageActionName: 'file',
        imageUrlPrefix: this.$imgBaseUrl
        // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
      },
      pattern: '', // edit add
      treeLoading: false,
      rules: {
        name: [{ required: true, message: '请输入目录名称', trigger: 'blur' }],
        code: [{ required: true, message: '请输入栏目编码', trigger: 'blur' }]
      },
      menuList: [],
      dialogVisible: false,
      menuItemLading: false,
      menuItem: {
        id: '',
        pid: '',
        url: '',
        icon: '',
        code: '',
        isHide: '1',
        name: '',
        sort: '',
        isTarget: '2',
        type: 1
      },
      isClear: false,
      data: {
        content: '', // 富文本内容
        consumeUserIds: [], // 接受用户id集合
        title: ''
      },
      EditoritemText: ''
    }
  },
  components: {
    VueUeditorWrap
  },
  watch: {
    'data.content': function (n, o) {
      this.EditoritemText = util.getPlainTxt(this.data.content)
    }
  },
  created () {
    this.getAllMenu()
  },
  methods: {
    Article (id) {
      this.$router.push({ path: '/admin/articlelist', query: { id: id } })
    },
    getAllMenu () {
      this.treeLoading = true
      GetColumnList({}).then(resp => {
        this.treeLoading = false
        if (resp.code === '000') {
          this.menuList = [...resp.result]
        }
      })
    },
    append (data) {
      this.pattern = 'add'
      if (data) {
        this.clearItemData(data.id, 1)
      } else {
        this.clearItemData('', 1)
      }
      this.dialogVisible = true
    },
    remove (id) {
      this.$confirm('此操作将删除该目录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(res => {
        ColumnDelete({ id: id }).then(resp => {
          if (resp.code === '000') {
            this.$message({ message: '删除成功', type: 'success' })
            this.getAllMenu()
          } else {
            this.$message({ message: '操作失败', type: 'error' })
          }
        })
      })
    },
    clickItem (data) {
      this.treeLoading = true
      this.pattern = 'edit'
      GetColumnListById({ id: data.id }).then(resp => {
        if (resp.code === '000') {
          const date = resp.result
          this.menuItem.id = date.id
          this.menuItem.name = date.name
          this.menuItem.code = date.code
          this.menuItem.pid = date.pid
          this.menuItem.url = date.url
          this.menuItem.icon = date.icon
          this.menuItem.isTarget = date.isTarget
          this.menuItem.isHide = date.isHide + ''
          this.menuItem.type = date.type
          this.menuItem.sort = date.sort
          this.data.content = date.content
          this.EditoritemText = date.contentPure
          this.treeLoading = false
          this.dialogVisible = true
        } else {
          this.$message({ message: resp.msg, type: 'error' })
        }
      })
    },
    submit () {
      this.$refs.menuItem.validate((valid) => {
        if (valid) {
          this.menuItemLading = true
          if (this.pattern === 'edit') {
            const data = this.menuItem
            if (data.type === 2) {
              if (!this.data.content || !this.EditoritemText) {
                this.$message({ message: '请编写栏目内容', type: 'error' })
                return
              }
              data.content = this.data.content
              data.contentPure = this.EditoritemText
            } else {
              data.content = ''
              data.contentPure = ''
            }
            UndateColumnList(data).then(resp => {
              this.menuItemLading = false
              if (resp.code === '000') {
                this.$message({ message: '操作成功', type: 'success' })
                this.getAllMenu()
                this.guanbi()
              } else {
                this.$message({ message: resp.msg, type: 'error' })
              }
            })
          } else if (this.pattern === 'add') {
            delete this.menuItem.id
            const data = this.menuItem
            if (data.type === 2) {
              if (!this.data.content || !this.EditoritemText) {
                this.$message({ message: '请编写栏目内容', type: 'error' })
                return
              }
              data.content = this.data.content
              data.contentPure = this.EditoritemText
            } else {
              data.content = ''
              data.contentPure = ''
            }
            AddColumnList(data).then(resp => {
              this.menuItemLading = false
              if (resp.code === '000') {
                this.$message({ message: '操作成功', type: 'success' })
                this.guanbi()
                this.getAllMenu()
              } else {
                this.$message({ message: resp.msg, type: 'error' })
              }
            })
          }
        } else {
          return false
        }
      })
    },
    guanbi () {
      this.dialogVisible = false
      this.clearItemData('', 1)
      this.EditoritemText = ''
      this.data = {
        content: '', // 富文本内容
        consumeUserIds: [], // 接受用户id集合
        title: ''
      }
    },
    clearItemData (pid, type) {
      this.menuItem.id = ''
      this.menuItem.pid = pid
      this.menuItem.code = ''
      this.menuItem.name = '新增节点'
      this.menuItem.url = ''
      this.menuItem.icon = ''
      this.menuItem.isHide = '1'
      this.menuItem.sort = ''
      this.menuItem.isTarget = '2'
      this.menuItem.type = type
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.pad-left-20{
  padding-left: 25px;
}
.menu-manage{
  width: 100%;
  height: 100%;
  padding: 20px 50px 30px 50px;
  .menu-tree{
    width: 60%;
    height: 100%;
    overflow: auto;
    padding: 20px;
    border-right: 1px solid #ddd;
  }
  .menu-item{
    width: 58%;
    margin-left: 2%;
    height: 100%;
    overflow: auto;
    padding: 20px;
  }
}
.menu-title{
  text-align: left;
  padding-left: 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 20px;
}
.operation{
  display: none;
}
.el-tree-node__content:hover .operation{
  display: inline-block;
}

</style>
